import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function SeoCopywritingPage(props) {
    return (
        <div className="container draft-view">
           <h1>SEO Copywriting</h1>
           <p className="text-muted">The main goal of your web content is to make you money, and a good SEO copy will make your business more attractive to both search engines and people. Without visibility on search engines, your website is a lost resource.</p>
           <p className="text-muted">Quality website copywriting services are a great way to boost relevant traffic and increase conversions for your website.</p>
           <p className="text-muted">Wescale has SEO copywriters at your disposal. We craft your content with careful research on your customer's searches, as well as thorough market analysis, and an understanding of information architecture and natural language processing. We will help you achieve the SEO content best fit to help you achieve your business goals.</p>
           <p className="text-muted"></p>
           <h3>How SEO copywriting helps your business</h3>
              <p> The primary goal of SEO copywriting is to create content that will attract traffic and convert visitors into leads and buyers. SEO copywriting is most commonly used to create web page content (home pages, product pages, service pages, catalogs, PPC landing pages, local SEO pages, blog posts.) How does SEO copy attract visitors to your website? The web page content is one of the key factors that search engines analyze. SEO copywriting makes page text more understandable for search engines. SEO copywriters use knowledge about search engines’ specific features and your target audience so that your content will be more visible in web searches for your potential customers. </p>
              <p className="text-muted"></p>
              <p> How does SEO copy convert your visitors into leads and buyers? SEO copywriting includes traditional copywriting that is based specifically on learning why and who the copy was written for. These methods can also help you to increase customer loyalty. SEO copy coupled with the right design can work wonders. In fact, investments in SEO copywriting is a very cost-effective way to generate sales. One work of SEO copy can convert thousands of visitors into customers. You can evaluate the return of investments easily by evaluating each purchase. In every case, you need a quality SEO copy in order to best promote your business on the Internet. </p>
           <p className="text-muted"><br /></p>
           <h2 >Wescale SEO copywriting service</h2><p  className="text-muted">What does our SEO copywriting service include? How much does SEO copy cost? We will answer all of your questions below.</p>
           <p className="text-muted"><br /></p>
           <h5>1. SEO copywriting research</h5>
           <p className="text-muted">The SEO copywriting research is the most important part because it helps us to best create effective SEO copy on the first try in order to save you money.</p>
           <p className="text-muted">This stage includes:</p>
           <ul>
              <li className="text-muted">product/service research</li>
              <li className="text-muted">customer research</li>
              <li className="text-muted">competitors research</li>
              <li className="text-muted">website factors research</li>
           </ul>
           <p className="text-muted">We provide the research of website factors in order to exclude on-page SEO factors that could have negative effects. If we discover any negative factors, we’ll give you free recommendations on how to best improve your website.</p>
           <p className="text-muted"><br /></p>
           <h5>2. Creating SEO copies</h5>
           <p className="text-muted">While others say that it is impossible to write content for both search engines and people, we are doing it.</p>
           <p className="text-muted">This stage includes:</p>
           <ul>
              <li className="text-muted">keywords research</li>
              <li className="text-muted">creating of concept</li>
              <li className="text-muted">creating of informational structure</li>
              <li className="text-muted">create SEO copies</li>
           </ul>
           <p className="text-muted">Our keywords research is a little bit different from the traditional approaches. Modern search engines use semantic search, PageRank, Natural Language Processing, prediction model, and other methods to help people best find relevant information. This approach allows us to create SEO content that will really work for you by giving you the best possible odds.</p>
           <p className="text-muted"><br /></p>
           <h5>3. SEO copies implementation</h5>
           <p className="text-muted">In this stage, SEO copies are ready. Texts have passed a grammar check, and you can verify SEO copies before they are published.</p>
           <p className="text-muted">This is a crucial stage of SEO copywriting service which includes:</p>
           <ul>
              <li className="text-muted">approval of SEO copies</li>
              <li className="text-muted">add SEO copies on pages</li>
              <li className="text-muted">monitor SEO and sales results</li>
              <li className="text-muted">improve copies</li>
           </ul>
           <p className="text-muted">While these first versions of SEO copies will bring you closer to reaching your goals, they can always be improved upon. The data from the webmaster and other analytical tools are a great source to improve texts and create advanced SEO copies. Also, it helps you to best stay ahead of the competition.</p>
           <p className="text-muted"><br /></p>
           <h5>4. SEO copywriting service cost</h5>
           <p className="text-muted">The average cost of SEO copywriting services can range from $25 to $2500 per webpage.</p>
           <p className="text-muted">Typical SEO copywriting costs include:</p>
           <ul>
              <li className="text-muted">Cost of research</li>
              <li className="text-muted">Cost of SEO copies creation</li>
              <li className="text-muted">Cost of monitor</li>
           </ul>
           <p className="text-muted">It also depends on the context of your project, problems, and priorities. For example, say that Wescale creates a new website for you. In this case, we exclude the stage of research from SEO copywriting services because we would have already completed complex research.</p>
           <p className="text-muted">In any case, even if the cost of page copy is $2500, you must keep in mind that this page has the potential to generate tens of thousands of dollars for you.</p>
           <h5><br /></h5>
           <p  className="text-muted">Although a haphazard approach may allow you to reduce the cost of SEO copywriting, we prefer to be systematic. Our approach is based on data, knowledge, experience, and respect for your clients. Don’t hesitate to contact us with questions or interest in our services!<br /></p>

        </div>
    );
}
