import React from 'react';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link as BLink} from "@material-ui/core";
import {Link} from "gatsby";
import Typography from "@material-ui/core/Typography";
import {onOpenApplyForm} from "../ApplyForm"
import "./style.styl"





export default function ServiceLayout(props) {

    return (
        <div className="service-page">
            <div className="u-marginBottom40 u-xs-marginBottom40 container u-flexCenter u-justifyContentSpaceBetween">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/">
                    <BLink color="inherit" className="u-cursorPointer">
                        Home
                    </BLink>
                    </Link>
                    <Typography color="textPrimary">{props.title}</Typography>
                </Breadcrumbs>

                {
                    props.isMobile ?
                        <div></div>
                        :
                        <span className="button-dark-blue" onClick={() => onOpenApplyForm("true", props.location)}>Request a Proposal</span>
                }
            </div>
            {props.children}
            <div className="u-flexCenter u-justifyContentCenter u-marginTop50">
                <span className="button-dark-blue large" onClick={() => onOpenApplyForm("true", props.location)}>Request a Proposal</span>
            </div>
        </div>
    )
}
